import React from 'react';
// import { Router } from '@reach/router';
import Layout from '../../../components/layout';

import PrivateRoute from './../../../components/s4n/AccountManagement/PrivateRoute';
import Profile from './../../../components/s4n/AccountManagement/Profile';

const User = () => {
  return (
    <Layout className={`dashboard_profile_details__page`}>
       
       <PrivateRoute component={Profile} />

    </Layout>
  )
}

export default User;