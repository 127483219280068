import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import withDrupalOauthConsumer from './../../../drupal-oauth/withDrupalOauthConsumer';
import { getMappedIncludes, getRelationshipFromMappedIncludes } from "@centarro/js-sdk"

// s4n
import { useIntl, Link } from 'gatsby-plugin-intl'
import { userLogin, userProfile, simplenewsSubscriber, profileFetch } from './../../Shop/ext/redux/actions/user'
import { wishlistFetch } from './../../Shop/ext/redux/actions/wishlist'
// import CommerceHelper from './../../../../Shop/s4n/utils/commerce-helper'
import Preloader from './../../Preloader'
// import Product from './../../../../../../model/commerce/product'
// import ProductImage from './../../../../Shop/Product/image'
// import ProductPrice from './../../../../Shop/Product/price'
import GeneralUtils from './../../../../utils/common'

import { DisplayFormikState as ObjectRenderer } from "./../../../forms/formikHelper"
// import ProfileCustomerAttributes from './../../../forms/Checkout/ProfileCustomerAttributes'
import { Accordion, Card, Tabs, Tab } from 'react-bootstrap'

import Breadcrumb from './../Breadcrumb'
// import Logo from './../../../../images/custom/logo.svg'
// import ShopCheckoutIframe from './../../../../components/s4n/Shop/s4n/Checkout/Iframe';
import LogoutLink from './../../../s4n/AccountManagement/LogoutLink';


const Component = (props) => {
    const intl = useIntl();
    const t = intl.formatMessage;

    const { user } = props; // THIS IS OK !!! we have props.user.user_profile :)

    const [loadingData, setLoadingData] = useState(true);
    // const [ profileCustomer, setProfileCustomer ] = useState(null);

    useEffect(() => {
        componentDidMount();
    }, []);

    const componentDidMount = async () => {
        const authorization = props.drupalOauthClient.isLoggedIn();
        const loggedInUserMail = user?.user_profile?.attributes?.mail;

        // console.log(loggedInUserMail)
        if (authorization) {
// console.log(authorization)
            await props.onUserLogin(authorization); // @todo: Here or after login in Login component or other better place ?? !!
            
            // we invoke it as well in in wrap-with-provider.js so after refresh these data are available
            // await props.onUserProfile(authorization);
            
            // loggedInUserMail && props.onSimplenewsSubscriber(authorization, 'GET', null, loggedInUserMail, {target_id: `default`});
            
            // we invoke it as well in in wrap-with-provider.js so after refresh these data are available
            // because we want to have this information on other pages like products listing even if we did not visitee wishlist page earlier!!!
            // await props.onWishlistFetch(authorization);

            setLoadingData(false);
        }
    }

    const userProfileAttributes = user?.user_profile?.attributes;
    // console.log(userProfileAttributes)

    if (loadingData || !userProfileAttributes) {
        return <Preloader />;
    }

    const userProfileAttributesToDisplay = [
        `name`, 
        `mail`, 
        // `preferred_langcode`, 
        // `timezone`, 
        `created`, 
        `changed`
    ];

    // return (
    //     <ShopCheckoutIframe />
    // )

    return (
        <React.Fragment>
            
            {/* <ObjectRenderer {...user?.user_profile?.attributes} /> */}
            {/* <ObjectRenderer {...props.user.simplenews_subscriber} /> */}

            <section className="user-dashboard section">
                <div className="container">
                    <div className="row">
                    <div className="col-md-12">
                        <Breadcrumb activeStep={`DASHBOARD_PROFILE_DETAILS`} />
                        <div className="dashboard-wrapper dashboard-user-profile">
                            <div className="media">
                                {false &&
                                    <div className="text-center">
                                        {/* <img className="media-object user-img" src="images/avater.jpg" alt="Image" /> */}
                                        <img className="media-object ___user-img" src={Logo} alt={`Image`} />

                                        {/* <a href="#" className="btn btn-sm mt-3 d-block">Change Image</a> */}
                                    </div>
                                }
                                <div className="media-body">
                                    <ul className="user-profile-list">
                                        {userProfileAttributesToDisplay.map(attributeName => {
                                            if (typeof userProfileAttributes[attributeName] === `undefined`) {
                                                return null;
                                            }

                                            let attributeValue = userProfileAttributes[attributeName];

                                            if ([`created`, `changed`].includes(attributeName)) {
                                                attributeValue = GeneralUtils.dateFormat(attributeValue, null, intl.locale);
                                            }

                                            return (
                                                <React.Fragment>
                                                    <li>
                                                        <span>{ t({ id: `soft4net_shop_commerce_account_management_profile_${attributeName}` }) }:</span>{attributeValue}
                                                    </li>
                                                </React.Fragment>
                                            )
                                        })}

                                        <li className={`pt-3`}></li>
                                        <li><Link to={`/user/password/reset`} className={`btn btn-danger    text-white user-password-reset`}>{ t({ id: `form_account_management_login_password_reset` }) }</Link></li>
                                        <li><LogoutLink component={Link} className={`btn btn-primary    text-white`}>Wyloguj</LogoutLink></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </section>

        </React.Fragment>
    )
};

const mapStateToProps = state => {
    return {
      user: state.shopReducers.user,
    }
};

const mapDispatchToProps = dispatch => ({
    onUserLogin: (authorization) => dispatch(userLogin(authorization)),
    // onUserProfile: (authorization) => dispatch(userProfile(authorization)),
    // onSimplenewsSubscriber: (authorization, method, status, mail, subscriptions) => dispatch(simplenewsSubscriber(authorization, method, status, mail, subscriptions)),
    // onWishlistFetch: (authorization) => dispatch(wishlistFetch(authorization)),
    // onProfileFetch: (authorization) => dispatch(profileFetch(authorization)),
    
})

export default connect(mapStateToProps, mapDispatchToProps)(withDrupalOauthConsumer(Component));
